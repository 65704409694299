.container {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
}

.lodading-overlay {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-button {
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 20%;
  position: absolute;
  right: 10vw;
  top: 8vw;
  width: 5vw;
  height: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 48px;
  max-height: 48px;
  padding: 0;
  margin: 0;

  img {
    width: 3vw;
    max-width: 32px;
    flex: 1;
  }
}
